import _ from "lodash";
function loopRoute(data, routes) {
  data.forEach((item) => {
    routes.push(item);
    if (item.children) {
      loopRoute(item.children || [], routes);
    }
  });
}

export function deepFlattenRoutes(data) {
  let routes = [];
  loopRoute(data, routes);
  return routes;
}
export function flattenRoute(data) {
  let routes = [];
  routes = data.reduce((re, item) => {
    re.push(item);
    if (item.children) {
      re = re.concat(item.children);
    }
    return re;
  }, []);
  return routes;
}

function sorteRoute(data, routes, doNotPush) {
  _.sortBy(data, "order_num").forEach((item) => {
    !doNotPush && routes.push(item);
    if (item.children) {
      item.children = _.sortBy(item.children, "order_num");
      sorteRoute(item.children, routes, true);
    }
  });
}

export function sortMenus(data) {
  const routes = [];
  sorteRoute(data, routes);
  return routes;
}
