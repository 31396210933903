<template>
  <div class="aside">
    <div class="aside-logo-content">
      <img :src="logo" class="logo" />
    </div>
    <div class="el-menu-content">
      <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical-demo"
        :default-openeds="openedMenus"
        router
      >
        <template v-for="m in authorizedMenus">
          <el-submenu
            :index="m.url"
            :key="m.url"
            v-if="m.children && m.children.length > 0"
          >
            <span slot="title" class="menuTitle">
              <i :class="`iconfont icon-${menusIconConfig[m.url]}`" />
              {{ m.menu_name }}
            </span>
            <el-menu-item :index="cM.url" :key="cM.url" v-for="cM in m.children">
              <i class="iconfont-empty" />
              <span slot="title">{{ cM.menu_name }}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item :index="m.url" :key="m.url" v-else>
            <span class="menuTitle">
              <i :class="menusIconConfig[m.url].indexOf('el-icon') === 0 ? menusIconConfig[m.url] : `iconfont icon-${menusIconConfig[m.url]}`" />
              <span slot="title">{{ m.menu_name }}</span>
            </span>
          </el-menu-item>
        </template>
      </el-menu>
    </div>
  </div>
</template>

<script>
import routes from "@/router/routes";
import { flattenRoute } from "@/utils/menu";
import { menusIconConfig } from "@/constants/menus";
import { mapState } from "vuex";
export default {
  data() {
    return {
      menusData: [],
      activeMenu: undefined,
      openedMenus: [],
      menusIconConfig,
      logo: require("../../assets/logo-main-new.png"),
    };
  },
  created() {
    this.getUserAuthorizedMenus();
  },
  mounted() {
    this.initActiveMenu();
  },
  computed: mapState(["authorizedMenus", "loginAccountInfo"]),
  watch: {
    $route(v) {
      //监听route的变化
      this.openedMenus = this.getOpenedKeys();
      //处理三级路由的左侧菜单选中问题
      const isThirdRoute = v.path.split("/").length > 3;
      const first = v.path.split("/")[1];
      const route = flattenRoute(routes).find(
        (item) => item.path == `/${first}`
      );
      //处理二级路由的左侧菜单选中问题
      const hasChildren = !_.isEmpty(route.children);
      this.activeMenu = isThirdRoute
        ? this.getOpenedKeys()[2]
        : hasChildren
        ? v.path
        : `/${v.path.split("/")[1]}`;
    },
  },
  methods: {
    getUserAuthorizedMenus() {
      if (!this.loginAccountInfo || !this.loginAccountInfo.user_id) return;
      this.$request
        .getUserAuthorizedMenus({
          user_id: this.loginAccountInfo.user_id,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error((res && res.msg) || '用户未登录');
            return;
          }
          this.$store.commit("setAuthorizedMenus", {
            data: res?.data?.children || [],
            donotJump: sessionStorage.getItem("routesData") ? true : false,
          });
        });
    },
    getOpenedKeys() {
      const { path } = this.$route;
      return _.compact(path.split("/")).reduce(
        (rs, item, index) => {
          rs.push(index - 1 >= 0 ? `${rs[index]}/${item}` : `/${item}`);
          return rs;
        },
        ["/"]
      );
    },
    initActiveMenu() {
      this.openedMenus = this.getOpenedKeys();
      this.activeMenu = this.$route.path;
    },
  },
};
</script>

<style  lang="scss">
.aside {
  height: 100%;
  box-sizing: border-box;
  .aside-logo-content {
    height: 60px;
    border-right: 1px solid #eceef5;
    border-bottom: 1px solid #eceef5;
    padding-top: 10px;
    box-sizing: border-box;
  }
  .logo {
    height: 38px;
    margin: 0px 0px 11px 38px;
    display: block;
  }
  .el-menu-content {
    height: calc(100% - 60px);
    overflow: auto;
  }
  .el-menu {
    // background-color: transparent !important;
    border: none;
  }
  .menuTitle {
    display: flex;
    align-items: center;
    font-size: 15px;
    .iconfont {
      font-size: 16px;
      margin-right: 8px;
    }
  }
  .iconfont-empty {
    margin-right: 8px;
  }

  .el-menu::before {
    display: none;
  }
  .el-submenu .el-menu-item {
    min-width: auto !important;
    font-size: 15px;
  }

  // .el-menu-item,
  // .el-submenu {
  //   color: hsla(0, 0%, 100%, 0.6);
  // }
  // .el-submenu__title {
  //   color: hsla(0, 0%, 100%, 0.6) !important;
  // }
  // .el-menu-item:hover,
  // .el-submenu__title:hover {
  //   background-color: #5e6173 !important;
  // }
  // .el-menu-item.is-active,
  // .el-submenu__title.is-active {
  //   background-color: #009cff !important;
  //   color: #fff;
  // }
}
</style>