import ajax from "./request.js";
import { HOST } from './base';

function queryList(data) {
  return ajax(`${HOST}/qtVersion/list`, {
    method: "POST",
    data
  });
}

function saveData(data) {
  return ajax(`${HOST}/qtVersion/save`, {
    method: "POST",
    data
  });
}


function updateData(data) {
  return ajax(`${HOST}/qtVersion/update`, {
    method: "POST",
    data
  });
}

function deleteDatas(data) {
  return ajax(`${HOST}/qtVersion/deleteByIds`, {
    method: "POST",
    data
  });
}

function updateStatus(data) {
  return ajax(`${HOST}/qtVersion/updateStatus`, {
    method: "POST",
    data
  });
}

function findDataById(data) {
  return ajax(`${HOST}/qtVersion/findById`, {
    method: "POST",
    data
  });
}

export default {
  queryList,
  saveData,
  updateData,
  deleteDatas,
  findDataById,
  updateStatus
};
