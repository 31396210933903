<template>
  <el-container>
    <el-aside width="200px" v-if="!$route.meta.hideMenu">
      <Aside />
    </el-aside>
    <el-container>
      <el-header v-if="!$route.meta.hideMenu">
        <AppHeader />
      </el-header>
      <div :class="!$route.meta.hideMenu ? 'appContent' : 'allScreen'">
        <router-view />
      </div>
    </el-container>
    <Guide v-if="isNotLogin"></Guide>
    <div v-loading.fullscreen.lock="isLoadingShow"></div>
  </el-container>
</template>

<script>
import Aside from "@/components/Aside";
import AppHeader from "@/components/AppHeader";
import Guide from '@/components/Guide';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isLoadingShow: 'getShowLoadingState'
    }),
    isNotLogin() {
      const path = this.$route.path;
      const isLogin = path === '/' || path === '/login' || path === '/register';
      return !isLogin;
    }
  },
  components: {
    Aside,
    AppHeader,
    Guide
  },
};
</script>
<style>
body {
  height: 100% !important;
  width: 100% !important;
}
html {
  width: 100%;
  height: 100%;
}
#app {
  font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.appContent {
  height: calc(100vh - 60px);
  background-color: rgb(245, 247, 250);
  overflow: auto;
  border-top: 1px solid #ebeef5;
}
.allScreen {
  height: 100%;
  width: 100%;
}
.el-header {
  padding: 0 !important;
}

.el-aside {
  /* background-color: rgb(51, 65, 84); */
  width: 204px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
  width: 100%;
  height: 100%;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
.el-button--text.delete-text-btn {
  color: #F56C6C;
}
.table-ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #c0c0c0;
}
</style>
