import store from "./index";
import eventBus from "@/utils/eventBus";
import toast from "@/utils/toast";
import generateID from "@/utils/generateID";
import { deepCopy, uuid } from "@/utils/utils";

export default {
  state: {
    copyData: null, // 复制粘贴剪切
    isCut: false,
  },
  mutations: {
    copy(state, isBatch) {
      if (!isBatch && !state.curComponent) {
        toast("请选择组件");
        return;
      }

      // 如果有剪切的数据，需要先还原
      restorePreCutData(state);
      copyData(state, isBatch);

      state.isCut = false;
    },

    paste(state, isMouse) {
      if (!state.copyData) {
        toast("请选择组件");
        return;
      }

      const data = state.copyData.data;
      if(Array.isArray(data)){ // 如果上一个复制是批量的
        const { areaStyle } = state.copyData;
        let missTop = 10;
        let missLeft = 10;
        if(isMouse) {
          missTop = state.menuTop - areaStyle.top;
          missLeft = state.menuLeft - areaStyle.left;
        }
        const components = data.map((item)=>{
          const _c = deepCopy(item)
          _c.id = uuid();
          _c.style.left += missLeft
          _c.style.top += missTop
          // Group 的子组件根节点的 id 是通过组件的 id 生成的，必须重新生成 id，否则拆分 Group 的时候获取根节点不正确
          if (_c.component === "Group") {
            _c.propValue.forEach((component) => {
              // component.id = new Date().valueOf();
              component.id = uuid();
            });
          }
          return _c
        })
        store.commit("batchAddComponents", { components });
      } else {
        if (isMouse) {
          data.style.top = state.menuTop;
          data.style.left = state.menuLeft;
        } else {
          data.style.top += 10;
          data.style.left += 10;
        }
  
        data.id = new Date().valueOf();
        // Group 的子组件根节点的 id 是通过组件的 id 生成的，必须重新生成 id，否则拆分 Group 的时候获取根节点不正确
        if (data.component === "Group") {
          data.propValue.forEach((component) => {
            component.id = new Date().valueOf();
            component.id = uuid();
          });
        }
  
        store.commit("addComponent", { component: deepCopy(data) });
      }
      if (state.isCut) {
        state.copyData = null;
      }
    },

    cut(state, isBatch) {
      if (!isBatch && !state.curComponent) {
        toast("请选择组件");
        return;
      }

      // 如果重复剪切，需要恢复上一次剪切的数据
      restorePreCutData(state);
      copyData(state, isBatch);
      if(isBatch) {
        store.commit("batchDeleteComponent", state.areaData.components);
        eventBus.$emit("hideArea");
      } else {
        store.commit("deleteComponent");
      }
      state.isCut = true;
    },
  },
};

// 恢复上一次剪切的数据
function restorePreCutData(state) {
  if (state.isCut && state.copyData) {
    const index = state.copyData.index;
    if(Array.isArray(state.copyData.data)) {
      let components = [];
      components = state.copyData.data.map(item=>{
        const _c = deepCopy(item)
        _c.id = new Date().valueOf();
        return _c
      })
      store.commit("batchAddComponents", { components, index });
    } else {
      data = deepCopy(state.copyData.data);
      data.id = new Date().valueOf();
      store.commit("addComponent", { component: data, index });

    }
    if (state.curComponentIndex >= index) {
      // 如果当前组件索引大于等于插入索引，需要加一，因为当前组件往后移了一位
      state.curComponentIndex++;
    }
  }
}

function copyData(state, isBatch) {
  let data = null;
  let index = null;
  let areaStyle = null;
  if(isBatch) {
    const { components = [], style } = state.areaData;
    // curComponentIndex是干什么用的？ 怎么还有undefined的时候
    // 多选的时候先不处理吧
    data = components.map(item=>{
      return deepCopy(item)
    })
    areaStyle = {...style}
  } else {
    data = deepCopy(state.curComponent),
    index = state.curComponentIndex
  }
  state.copyData = {
    data,
    index,
    areaStyle // 便于粘贴时计算组件位置
  };
}
