export const matrixOptions = [
  {
    component: "MatrixIn",
    type: "matrix-in",
    label: "输入",
    networkConfig: {
      input: "",
    },
  },
  {
    component: "MatrixOut",
    type: "matrix-out",
    label: "输出",
    networkConfig: {
      output: "",
    },
  },
  {
    component: "MatrixExecute",
    type: "matrix-execute",
    label: "执行",
    networkConfig: {
      rulesEngine: "",
      ipAdress: "",
      port: undefined,
    },
  },
];

export const TEMPLATES_STATUS = {
  0: {
    text: "待审批",
    class: "info",
  },
  1: {
    text: "审批通过",
    class: "success",
  },
  2: {
    text: "审批驳回",
    class: "danger",
  },
};

// export const DISABLED_SIZE_COMPONENTS = ["Weather", "Environment"];
export const DISABLED_SIZE_COMPONENTS = [];

// 生态组件的标志
export const ECOLOGICAL_SIGN = 'ecological';
// 生态组件子组件的标志
export const ECOLOGICAL_CHILDREN_SIGN = 'ecological_children';
