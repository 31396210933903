import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import store from "@/store";
import { ECOLOGICAL_SIGN } from '@/constants/editor';

export function deepCopy(target) {
  if (typeof target == "object") {
    const result = Array.isArray(target) ? [] : {};
    for (const key in target) {
      if (typeof target[key] == "object" && target[key] != null) {
        result[key] = deepCopy(target[key]);
      } else {
        result[key] = target[key];
      }
    }

    return result;
  }

  return target;
}

export function swap(arr, i, j) {
  const temp = arr[i];
  Vue.set(arr, i, arr[j]);
  Vue.set(arr, j, temp);
}

export function $(selector) {
  return document.querySelector(selector);
}

export const getPicSrc = (s) => {
  if (!s) return;
  return s.split("(")[1]?.split(")")[0];
};

export const dateTransform = (d) => {
  if (!d) return;
  return moment(d).format("YYYY-MM-DD HH:mm");
};

export const getComponentsByType = (type) => {
  const allComponents = JSON.parse(sessionStorage.getItem("canvasData"));
  return _.flatten(Object.values(allComponents)).reduce((res, c) => {
    if (c.component == type) {
      res.push(c);
    }
    return res;
  }, []);
};

export function toThousands(num, signal = ',') {
  num = '' + num;
  let result = '';
  if (num.includes('.')) {
      const dotIndex = num.indexOf('.');
      result = num.slice(dotIndex);
      num = num.slice(0, dotIndex);
  }
  while (num.length > 3) {
      result = signal + num.slice(-3) + result;
      num = num.slice(0, num.length - 3);
  }
  if (num) {
      result = num + result;
  }
  return result;
}
export function uuid() {
  // var s = [];
  // var hexDigits = "0123456789abcdef";
  // for (var i = 0; i < 36; i++) {
  //     s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  // }
  // s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  // s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  // s[8] = s[13] = s[18] = s[23] = "-";

  // var uuid = s.join("");
  var uuid = new Date().valueOf();
  uuid = Number(uuid + _.uniqueId());
  // console.log(uuid);
  return uuid;
}

export function validateIp(val) {
  var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
  return reg.test(val.trim());
}

export function isEmpty(val) {
  const isNull = !val && val !== 0;
  return val === '' || typeof val === 'undefined' || isNull;
}

// post请求，下载二进制的Excel表格
export const downloadFileByBlob = (url, body, successCallback) => {
  let { loginAccountInfo } = store.state || {};
  fetch(url, {
      method: 'POST',
      headers: {
          'Content-type': 'application/json;charset=UTF-8',
          "token": loginAccountInfo.token
      },
      body
  }).then(res => {
      if (successCallback) {
        successCallback();
      }
      res.blob().then(blob => {
          const filename = res.headers.get('Content-Disposition') && res.headers.get('Content-Disposition').replace(/attachment;.*filename=/, '')
              || `${+new Date()}.xls`;
          if (window.navigator.msSaveOrOpenBlob) {
              // 兼容ie10
              navigator.msSaveBlob(blob, filename);
          } else {
              const a = document.createElement('a');
              // 兼容火狐，将a标签添加到body当中
              document.body.appendChild(a);
              // 获取 blob 本地文件连接 (blob 为纯二进制对象，不能够直接保存到磁盘上)
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = filename;
              // a标签增加target属性
              a.target = '_blank';
              a.click();
              // 移除a标签
              a.remove();
              window.URL.revokeObjectURL(url);
          }
      });
  });
};

export function getCurComponentsInd(all, curComp) {
  let finalInd = -1;
  let finalParentInd = -1;
  for (let i = 0;i < all.length;i++) {
    if (finalParentInd !== -1) {
      break;
    }
    if (all[i].id === curComp.id) {
      finalInd = i;
      break;
    } else if (all[i].compType === ECOLOGICAL_SIGN) {
      // 生态组件下的子组件也需要查找
      if (all[i].propValue && all[i].propValue.length !== 0) {
        for (let j = 0;j < all[i].propValue.length;j++) {
          if (all[i].propValue[j].id === curComp.id) {
            finalInd = j;
            finalParentInd = i;
            break;
          }
        }
      }
    }
  }
  return {
    index: finalInd,
    parentInd: finalParentInd
  }
}

export function leftpad(str, len, ch = ' ') {
  str = str + '';
  len = len - str.length;
  if (len <= 0) {
      return str;
  }
  ch = ch + '';
  let pad = '';
  while (len) {
      if (len & 1) {
          pad += ch;
      }
      len >>= 1;
      ch += ch;
  }
  return pad + str;
}
/**
 * 获取日期格式输出
 * @param  {Date}   time   时间
 * @param  {String} format 输出格式，要求必须含有yyyy,mm,dd
 * @return {String}        默认返回yyyymmdd格式时间
 */
 export function getTimeStr(time, format = 'yyyy-MM-dd') {
  if (typeof time === 'string' ||
      typeof time === 'number') {
      time = new Date(time);
  }
  return format
      .replace('yyyy', time.getFullYear())
      .replace('MM', leftpad(time.getMonth() + 1, 2, '0'))
      .replace('dd', leftpad(time.getDate(), 2, '0'))
      .replace('HH', leftpad(time.getHours(), 2, '0'))
      .replace('mm', leftpad(time.getMinutes(), 2, '0'))
      .replace('ss', leftpad(time.getSeconds(), 2, '0'));
}

/**
 * 获取cookie
 * @param {String} name  cookie名
 * @param {String} defaultValue  如果没有获取到，返回的默认值
 */
 export function getCookie(name, defaultValue) {
  var value;
  var cookie = document.cookie;
  var start = cookie.indexOf(name + '=');
  var end;
  if (start !== -1) {
      start += name.length + 1;
      end = cookie.indexOf(';', start);
      if (end === -1) {
          end = cookie.length;
      }
      value = decodeURIComponent(cookie.substring(start, end) || '');
  }
  return value || defaultValue || '';
}
/**
* 设置cookie
* @param {String} name cookie名 必填
* @param {String} value  cookie值 必填
* @param {Number} expires  有效期 选填
* @param {String} domain 域 选填
*/
export function setCookie(name, value, expires, domain) {
  var sCookie = name + '=' + encodeURIComponent(value);
  if (expires) {
      var exp = new Date(new Date().getTime() + expires);
      sCookie += '; expires=' + exp.toGMTString();
  }
  // domain = domain || window.location.host;
  if (domain) {
      sCookie += '; domain=.' + domain;
  }
  sCookie += '; path=/';
  document.cookie = sCookie;
}

export function removeCookie(name) {
  if (name) {
    var cval = getCookie(name);
    if (cval != null){
      var sCookie = name + '=' + cval;
      var exp = new Date(new Date().getTime() - 1);
      sCookie += '; expires=' + exp.toGMTString();
      sCookie += '; path=/';
      document.cookie = sCookie;
    }
  }
}