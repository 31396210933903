import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/role`
  : "/api/cloud/role";

function queryRoles(data) {
  return ajax(`${href}/queryRoleList`, {
    method: "POST",
    data,
  });
}

function menuaAthorizeOperation(data) {
  const isDel = data.isDelete;
  delete data.isDelete;
  return ajax(`${href}/${isDel ? "delAuthorize" : "authorize"}`, {
    method: "POST",
    data,
  });
}

export default { queryRoles, menuaAthorizeOperation };
