import { validateIp, isEmpty, uuid } from './utils';
export const commonAttrs = [{ key: "borderRadius", label: "圆角" }];

export const fontStyle = [
  { key: "fontSize", label: "字体大小" },
  { key: "color", label: "字体颜色" },
];

export const fontFamilyOptions = [
  {
    text: "空心字体",
    id: '1'
  },
  // {
  //   text: "思源宋体 Regular字体",
  //   id: '2'
  // },
  // {
  //   text: "思源宋体 SemiBold字体",
  //   id: '3'
  // },
  {
    text: "思源黑体SourceHanSans",
    id: '4'
  },
  // {
  //   text: "思源黑体字体",
  //   id: '5'
  // },
  {
    text: "米开飘逸行楷减细版字体",
    id: '7'
  },
  {
    text: "arial unicode ms字体",
    id: '8'
  },
  {
    text: "arial bold字体",
    id: '9'
  }
];

export const fontFamily = [
  { key: "fontFamily", label: "字体", options: fontFamilyOptions }
];

export const fontFamilyMap = {
  '1': 'kongxin',
  '2': 'sysRegular',
  '3': 'sysSemiBold',
  '4': 'sysSourceHanSans',
  '5': 'syht',
  '7': 'mkpyxk',
  '8': 'arialUnicode',
  '9': 'arialBold'
};

export const positionInfoOptions = [
  { key: "left", label: "X" },
  { key: "top", label: "Y" },
  { key: "width", label: "W" },
  { key: "height", label: "H" },
];

export const disabledSizePositions = [
  { key: "left", label: "X" },
  { key: "top", label: "Y" },
  { key: "width", label: "W", disabled: true },
  { key: "height", label: "H", disabled: true },
];

export const btnComponentAttrs = [
  { key: "inputContent", label: "按钮名称" },
  { key: "btnPressName", label: "按钮按压名称" },
  { key: "alias", label: "别名" },
  ...commonAttrs,
  ...fontFamily,
  ...fontStyle,
  {
    key: "btnLoading",
    label: "按钮loading",
  },
  { key: "btnLoadingTime", label: "持续时间" },
  { key: "kqds", label: "开启定时" },
  { key: "oneClick", label: "是否单击按钮" },
  { key: "modalConfirm", label: "是否开启弹窗确认" },
  { key: "pressRing", label: "是否开启按压音效" },
  {
    key: "backgroundType", label: "背景", options: [
      { label: '颜色', value: 'color' },
      { label: '图片', value: 'icon' }
    ]
  },
  { key: "backgroundColor", parentKey: 'backgroundType', typeValue: 'color', label: "背景颜色" },
  { key: "backgroundImage", parentKey: 'backgroundType', typeValue: 'icon', label: "背景图" },
  {
    key: "pressBackgroundType", label: "按压背景", options: [
      { label: '颜色', value: 'color' },
      { label: '图片', value: 'icon' }
    ]
  },
  { key: "pressBackgroundColor", parentKey: 'pressBackgroundType', typeValue: 'color', label: "按压颜色" },
  { key: "pressBackgroundImage", parentKey: 'pressBackgroundType', typeValue: 'icon', label: "按钮按压背景图" },
];

const lineAttrs = [
  {
    key: "backgroundColor",
    label: "颜色",
  }
];

const imageAttrs = [
  {
    key: "imageResource",
    label: "图片资源",
  },
  {
    key: "backgroundSize",
    label: "图片尺寸",
    options: [
      {
        id: "contain",
        text: "铺满",
      },
      {
        id: "100% 100%",
        text: "等比例缩放",
      },
    ],
  },
];

const textAttrs = [
  ...fontFamily,
  ...fontStyle,
  { key: "inputContent", label: "内容" },
  { key: "backgroundColor", label: "背景颜色" },
  {
    key: "textScroll",
    label: "文字滚动开启",
  },
  {
    key: "textScrollDir",
    label: "滚动方向",
    options: [
      {
        id: "leftToRight",
        text: "从左到右",
      },
      {
        id: "rightToleft",
        text: "从右到左",
      },
    ],
  },
  {
    key: "textScrollSpeed",
    label: "滚动速度",
    options: [
      {
        id: "normal",
        text: "正常",
      },
      {
        id: "slow",
        text: "慢速",
      },
      {
        id: "fast",
        text: "快速",
      },
    ],
  },
];

const streamMediaAttrs = [
  {
    key: "rtspAdressGroups",
    label: "Rtsp流地址",
  },
];

const videoAttrs = [
  {
    key: "videoNameGroups",
    label: "视频地址",
  },
];

const webpageAttrs = [
  {
    key: "url",
    label: "网页地址",
    placeholder: '例：https://cloud.wird.com.cn/'
  },
];

const sliderAttrs = [
  {
    key: "direction",
    label: "方向",
    options: [
      {
        id: "horizontal",
        text: "横向",
      },
      {
        id: "vertical",
        text: "纵向",
      },
    ]
  },
  { key: "circleColor", label: "圆点颜色" },
  { key: "circleSize", label: "圆点大小" },
  { key: "trackColor", label: "轨道颜色" },
  { key: "activeColor", label: "滑动颜色" },
  { key: "trackSize", label: "轨道大小" },
  { key: "fontColor", label: "字体颜色" },
  { key: "fontSize", label: "字体大小" }
];

const documentAttrs = [
  {
    key: "documentNameGroups",
    label: "文档地址",
  },
];

const backAttrs = [
  {
    key: "backPicSrc",
    label: "背景图片",
  },
  {
    key: "pageName",
    label: "页面名称",
  },
  // {
  //   key: "pagesizeWidth",
  //   label: "屏幕尺寸-宽",
  // },
  // {
  //   key: "pagesizeHeight",
  //   label: "屏幕尺寸-高",
  // },
  {
    key: "grid",
    label: "网格",
  },
  {
    key: "backgroundColor",
    label: "背景色",
  },
];

const weatherAttrs = [
  {
    key: "city",
    label: "城市",
  },
  {
    key: "imgStyle",
    label: "样式",
    options: []
  },
  // {
  //   key: "color",
  //   label: "字体颜色",
  // },
  // {
  //   key: "backgroundColor",
  //   label: "背景颜色",
  // },
];

const matrixAttrs = btnComponentAttrs.filter((item) => item.key != "oneClick");

const DateAttrs = fontStyle;

const statusLightAttrs = [
  { label: '背景', key: 'lightBackground', type: 'resultRadio', colorKey: 'lightBackgroundColor', iconKey: 'lightBackgroundImage' }
];

const carouselAttrs = [
  {
    key: "carouselPics",
    label: "图片列表",
  },
  {
    key: "interval",
    label: "轮播间隔",
  },
  {
    key: "backgroundSize",
    label: "图片尺寸",
    options: [
      {
        id: "contain",
        text: "铺满",
      },
      {
        id: "100% 100%",
        text: "等比例缩放",
      },
    ],
  },
];

export const statusLightActive = [
  {
    key: "logRecordStatus",
    label: "启用状态log记录",
    type: 'el-radio',
    options: [
      {
        id: "1",
        text: "是",
      },
      {
        id: "0",
        text: "否",
      },
    ]
  },
  {
    key: "deviceName",
    label: "设备名称",
    type: 'el-input'
  },
  {
    key: "ip",
    label: "IP地址",
    type: 'el-input'
  },
  {
    key: "port",
    label: "端口号",
    type: 'el-input'
  },
];

export const COMPONENT_ATTRS_CONFIG = {
  LineComp: {
    positionInfoOptions,
    componentAttrs: lineAttrs,
  },
  Picture: {
    positionInfoOptions,
    componentAttrs: imageAttrs,
  },
  MatrixIn: {
    positionInfoOptions,
    componentAttrs: [...matrixAttrs],
  },
  MatrixOut: {
    positionInfoOptions,
    componentAttrs: matrixAttrs,
  },
  MatrixExecute: {
    positionInfoOptions,
    componentAttrs: matrixAttrs,
  },
  "v-button": {
    positionInfoOptions,
    componentAttrs: btnComponentAttrs,
  },
  "v-text": {
    positionInfoOptions,
    componentAttrs: textAttrs,
  },
  StreamMedia: {
    positionInfoOptions,
    componentAttrs: streamMediaAttrs,
  },
  Video: {
    positionInfoOptions,
    componentAttrs: videoAttrs,
  },
  Document: {
    positionInfoOptions,
    componentAttrs: documentAttrs,
  },
  Webpage: {
    positionInfoOptions,
    componentAttrs: webpageAttrs,
  },
  Slider: {
    positionInfoOptions,
    componentAttrs: sliderAttrs,
  },
  Back: {
    componentAttrs: backAttrs,
  },
  Weather: {
    positionInfoOptions,
    componentAttrs: weatherAttrs,
    // positionInfoOptions: disabledSizePositions,
  },
  Carousel: {
    positionInfoOptions,
    componentAttrs: carouselAttrs,
  },
  Date: {
    componentAttrs: DateAttrs,
  },
  Environment: {
    positionInfoOptions,
    // positionInfoOptions: disabledSizePositions,
  },
  StatusLight: {
    positionInfoOptions,
    componentAttrs: statusLightAttrs,
  }
};

export const NO_MATCH_COMPONENT_ATTRS = {
  positionInfoOptions,
};

export const BACK_COMPONENT = (pageName = "新页面1") => {
  return {
    component: "Back",
    id: "Back",
    label: "背景",
    style: {
      pageName,
      grid: true,
      backgroundColor: "#FFFFFF",
    },
  };
};

const timeOptions = [
  { label: '3', value: '3' },
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '15', value: '15' },
  { label: '20', value: '20' },
  { label: '25', value: '25' },
  { label: '30', value: '30' },
  { label: '35', value: '35' },
  { label: '40', value: '40' },
  { label: '45', value: '45' },
  { label: '50', value: '50' },
  { label: '55', value: '55' },
  { label: '60', value: '60' },
  { label: '120', value: '120' },
  { label: '180', value: '180' }
];

// 主动探测/被动监听中一个设备的配置项
export const DEVICE_CONFIG = [
  { label: '设备名称', key: 'deviceName', type: 'input' },
  { label: 'IP地址', key: 'ipAdress', type: 'input' },
  { label: '端口号', key: 'port', type: 'input', inputType: 'number' },
  // { label: '绑定指令', key: 'bindDirectives', type: 'button' },
  // { label: '探测时间', key: 'time', type: 'select', width: '70px', prefix: '每', suffix: '秒', options: timeOptions },
  // { label: '校验正确', key: 'validateCorrect', type: 'resultRadio', colorKey: 'validateCorrectColor', iconKey: 'validateCorrectIcon' },
  // { label: '校验失败', key: 'validateFail', type: 'resultRadio', colorKey: 'validateFailColor', iconKey: 'validateFailIcon' },
  {
    label: 'Ping设置', key: 'pingStatus', type: 'radio', options: [
      { label: '是', value: '1' },
      { label: '否', value: '0' }
    ]
  },
  { label: 'Ping探测', parentKey: 'pingStatus', key: 'pingTime', type: 'select', width: '70px', prefix: '每', suffix: '秒', options: timeOptions },
  { label: 'Ping成功', parentKey: 'pingStatus', key: 'pingCorrect', type: 'resultRadio', colorKey: 'pingCorrectColor', iconKey: 'pingCorrectIcon' },
  { label: 'Ping失败', parentKey: 'pingStatus', key: 'pingFail', type: 'resultRadio', colorKey: 'pingFailColor', iconKey: 'pingFailIcon' }
];

// 被动监听-设备配置
export const PASSIVE_MONITOR_DEVICE_CONFIG = [
  { label: '设备名称', key: 'deviceName', type: 'input' },
  { label: 'IP地址', key: 'ipAdress', type: 'input' },
  { label: '端口号', key: 'port', type: 'input', inputType: 'number' },
  { label: '绑定指令', key: 'bindDirectives', type: 'button' },
  { label: '探测时间', key: 'time', type: 'select', width: '70px', prefix: '每', suffix: '秒', options: timeOptions },
  { label: '设备回码信息', key: 'deviceCalls', type: 'deviceCalls' }
];

// 设备回码信息配置
export const DEVICE_CALLBACK_CONFIG = [
  { label: '设备回码', key: 'callbackInfo', type: 'input' },
  {
    label: '指令进制', key: 'callbackUnicode', type: 'radio', options: [
      { label: '字符串', value: '1' },
      { label: '16进制', value: '2' }
    ]
  },
  { label: '回码状态', key: 'callbackStatus', type: 'resultRadio', colorKey: 'callbackStatusColor', iconKey: 'callbackStatusIcon' }
];

// 主动探测配置项
export const ACTIVE_DETECTION_CONFIG = [
  {
    label: '启用状态log记录', key: 'logStart', type: 'radio', options: [
      { label: '是', value: '1' },
      { label: '否', value: '0' }
    ]
  }
].concat(DEVICE_CONFIG);

// 被动监听配置项
export const PASSIVE_MONITOR_CONFIG = [
  {
    label: '启用状态log记录', key: 'logStart', type: 'radio', options: [
      { label: '是', value: '1' },
      { label: '否', value: '0' }
    ]
  },
  { label: '设备信息', key: 'devices' }
];

// 每个设备的初始数据
export const INIT_DEVICE_DATA = {
  deviceName: '',
  ipAdress: '',
  port: '',
  // bindDirectives: [],
  // time: '1',
  // validateCorrect: 'color',
  // validateFail: 'color',
  // validateCorrectColor: '',
  // validateCorrectIcon: '',
  // validateFailColor: '',
  // validateFailIcon: '',
  pingTime: '1',
  pingCorrect: 'color',
  pingFail: 'color',
  pingCorrectColor: '',
  pingCorrectIcon: '',
  pingFailColor: '',
  pingFailIcon: ''
}

// 被动监听设备的初始数据
export const INIT_PASSIVE_DEVICE_DATA = {
  deviceName: '',
  ipAdress: '',
  port: '',
  time: '3',
  bindDirectives: [],
  deviceCalls: [
    { id: uuid(), callbackInfo: '', callbackUnicode: '1', callbackStatus: 'color', callbackStatusColor: '#67c23a', callbackStatusIcon: '' }
  ]
}

export const COMPONENT_NETWORDK_CONFIG_ATTRS = {
  MatrixIn: [
    {
      label: "输入位",
      key: "input",
    },
  ],
  MatrixOut: [
    {
      label: "输出位",
      key: "output",
    },
  ],
  MatrixExecute: [
    {
      label: "规则引擎",
      key: "rulesEngine",
    },
    {
      label: "IP地址",
      key: "ipAdress",
    },
    {
      label: "端口",
      key: "port",
    },
  ],
  ["v-button"]: [
    // {
    //   label: "端口",
    //   key: "port",
    // },
    // {
    //   label: "IP地址",
    //   key: "ipAdress",
    // },
    {
      label: "按压指令",
      key: "directives",
    },
    {
      label: "二次按压指令",
      key: "directives2",
    },
  ],
  Environment: [
    {
      label: "IP地址",
      key: "ipAdress",
    },
    {
      label: "端口",
      key: "port",
    },
  ],
  Slider: [
    {
      label: "IP地址",
      key: "ipAdress",
    },
    {
      label: "端口",
      key: "port",
    },
    // {
    //   label: "总数",
    //   key: "count",
    // },
    {
      label: "单元设置",
      key: "sliderList",
    },
  ],
  EcologicalGroup: [
    {
      label: "IP地址",
      key: "ipAdress",
    },
    {
      label: "端口",
      key: "port",
    }
  ]
};

const commonNotEmpty = (val, callback) => {
  if (isEmpty(val)) {
    callback('不能为空');
    return;
  }
  callback();
};

export const ATTRS_VALIDATE_MAP = {
  width: {
    validate: commonNotEmpty
  },
  height: {
    validate: commonNotEmpty
  },
  top: {
    validate: commonNotEmpty
  },
  left: {
    validate: commonNotEmpty
  },
  url: {
    validate: commonNotEmpty
  },
  fontSize: {
    min: 0,
    precision: 0,
    default: 14
  },
  borderRadius: {
    min: 0,
    precision: 0,
    default: 0
  },
  interval: {
    min: 0,
    precision: 0
  },
  btnLoadingTime: {
    min: 0,
    precision: 0,
    validate: commonNotEmpty
  },
  pageName: {
    // maxlength: 4
    validate(val, callback) {
      // 只有按钮的“开启定时”勾选时，别名才必填
      if (val.trim().length > 8) {
        callback('页面名称不能超过8个字符');
        return;
      }
      callback();
    }
  },
  port: {
    min: 0,
    precision: 0
  },
  ipAdress: {
    validate(val, callback) {
      if (val && val.trim() !== '') {
        let isRight = validateIp(val);
        if (!isRight) {
          callback('请输入正确的IP地址');
          return;
        }
      }
      callback();
    }
  },
  alias: {
    validate(val, callback, otherVal) {
      // 只有按钮的“开启定时”勾选时，别名才必填
      // if (otherVal.kqds) {
      if (isEmpty(val.trim())) {
        callback('别名不能为空');
        return;
      }
      // }
      callback();
    }
  },
  sliderList: {
    validate(val, callback, otherVal) {
      if (!val || val.length < 2) {
        callback('至少要有两个');
        return;
      }
      // 每一个的单位、代码都不能为空
      let hasEmpty = false;
      // let count = 0;
      val.map((item) => {
        if (_.isEmpty(item.unit + '') || _.isEmpty(item.code)) {
          hasEmpty = true;
        }
        // count += +item.count;
      });
      if (hasEmpty) {
        callback('每一个的单位、代码都不能为空');
        return;
      }
      // if (count > +otherVal.count) {
      //   callback('单位相加的和不能大于总数');
      //   return;
      // }
      callback();
    }
  },
  videoNameGroups: {
    validate(val, callback) {
      if (!val || val.length === 0) {
        callback('至少要有一个');
        return;
      }
      // 每一个的文件名称不能为空
      let hasEmpty = false;
      val.map((item) => {
        if (_.isEmpty(item.name)) {
          hasEmpty = true;
        }
      });
      if (hasEmpty) {
        callback('每一个的文件名称不能为空');
        return;
      }
      callback();
    }
  },
  documentNameGroups: {
    validate(val, callback) {
      if (!val || val.length === 0) {
        callback('至少要有一个');
        return;
      }
      // 每一个的文件名称不能为空
      let hasEmpty = false;
      val.map((item) => {
        if (_.isEmpty(item.name)) {
          hasEmpty = true;
        }
      });
      if (hasEmpty) {
        callback('每一个的文件名称不能为空');
        return;
      }
      callback();
    }
  },
  input: {
    validate: commonNotEmpty
  },
  output: {
    validate: commonNotEmpty
  }
};
