const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   redirect: "/homePage",
  // },
  {
    path: "/login",
    component: () => import("@/views/Login"),
    meta: {
      hideMenu: true,
    },
  },
  {
    path: "/register",
    component: () => import("@/views/Register"),
    meta: {
      hideMenu: true,
    },
  },
  {
    path: "/homePage",
    name: "Homepage",
    title: "我的首页",
    component: () => import("@/views/HomePage"),
  },
  {
    path: "/uiDesign",
    name: "UIDesign",
    title: "UI设计",
    component: () => import("@/views/UIDesign"),
  },
  {
    path: "/homePage/moreTemplates",
    title: "更多模板",
    component: () => import("@/views/MoreTemplates"),
  },
  //素材管理相关
  {
    path: "/privateResource",
    title: "私有资源库",
    component: () => import("@/views/PrivateResource"),
    children: [
      {
        path: "/privateResource",
        redirect: "/privateResource/materials",
      },
      {
        path: "/privateResource/materials",
        title: "素材管理",
        component: () => import("@/views/PrivateResource/Materials"),
      },
      {
        path: "/privateResource/directiveTypes",
        title: "指令类型",
        component: () => import("@/views/PrivateResource/DirectiveTypes"),
      },
      {
        path: "/privateResource/directiveTypes/edit",
        title: "编辑",
        component: () => import("@/views/PrivateResource/DirectiveTypes/Edit"),
      },
      {
        path: "/privateResource/directiveTypes/add",
        title: "新增",
        component: () => import("@/views/PrivateResource/DirectiveTypes/Edit"),
      },
      {
        path: "/privateResource/manage",
        title: "私有指令",
        component: () => import("@/views/PrivateResource/Directives"),
      },
      {
        path: "/privateResource/manage/edit",
        title: "编辑",
        component: () => import("@/views/PrivateResource/Directives/Edit"),
      },
      {
        path: "/privateResource/manage/add",
        title: "新增",
        component: () => import("@/views/PrivateResource/Directives/Edit"),
      },
    ]
  },
//公共资源库
{
  path: "/publicResource",
  title: "公共资源库",
  component: () => import("@/views/PublicResource"),
  children: [
    {
      path: "/publicResource",
      redirect: "/publicResource/publicDirective",
    },
    {
      path: "/publicResource/publicDirective",
      title: "公共指令",
      component: () => import("@/views/PublicResource/PublicDirective")
    },
    {
      path: "/publicResource/publicDirective/add",
      title: "公共指令新增",
      component: () => import("@/views/PublicResource/PublicDirective/Edit")
    },
    {
      path: "/publicResource/publicDirective/edit",
      title: "公共指令编辑",
      component: () => import("@/views/PublicResource/PublicDirective/Edit")
    },
    {
      path: "/publicResource/publicDirective/view",
      name: 'publicDirectiveView',
      title: "公共指令查看",
      component: () => import("@/views/PublicResource/PublicDirective/Edit")
    },
    {
      path: "/publicResource/publicMaterial",
      title: "公共素材",
      component: () => import("@/views/PublicResource/PublicMaterial"),
    },
  ],
},
//APK升级
{
  path: "/apk",
  title: "APK升级",
  component: () => import("@/views/APKPage")
},
{
  path: "/apk/add",
  title: "apk新增",
  component: () => import("@/views/APKPage/Edit")
},
{
  path: "/apk/edit",
  title: "apk修改",
  component: () => import("@/views/APKPage/Edit")
},
//QT升级
{
  path: "/qt",
  title: "QT升级",
  component: () => import("@/views/QTPage")
},
{
  path: "/qt/add",
  title: "qt新增",
  component: () => import("@/views/QTPage/Edit")
},
{
  path: "/qt/edit",
  title: "qt修改",
  component: () => import("@/views/QTPage/Edit")
},
  //工程管理相关
  {
    path: "/projects",
    title: "工程管理",
    component: () => import("@/views/Projects"),
  },
  {
    path: "/projects/projectsEdit",
    title: "工程编辑",
    component: () => import("@/views/VisualDrag"),
    meta: {
      hideMenu: true,
    },
  },
  {
    path: "/projects/add",
    title: "新建工程",
    component: () => import("@/views/VisualDrag"),
    meta: {
      hideMenu: true,
    },
  },
  //帮助
  {
    path: "/helps",
    title: "帮助",
    component: () => import("@/views/Helps"),
  },
  {
    path: "/helps/edit",
    title: "详情",
    component: () => import("@/views/Helps/Detail"),
  },
  //用户管理
  {
    path: "/userManage",
    title: "用户管理",
    component: () => import("@/views/UserManage"),
    children: [
      {
        path: "/userManage",
        redirect: "/userManage/users",
      },
      {
        path: "/userManage/users",
        title: "注册用户",
        component: () => import("@/views/UserManage/Users"),
      },
      {
        path: "/userManage/managers",
        title: "管理员",
        component: () => import("@/views/UserManage/Managers"),
      },
    ],
  },
  {
    path: "/userManage/users/edit",
    title: "编辑",
    component: () => import("@/views/UserManage/Users/Edit"),
  },
  {
    path: "/userManage/managers/edit",
    title: "编辑",
    component: () => import("@/views/UserManage/Managers/Edit"),
  },
  {
    path: "/userManage/managers/add",
    title: "新增",
    component: () => import("@/views/UserManage/Managers/Edit"),
  },
  //密钥管理
  {
    path: "/secretKeys",
    title: "密钥管理",
    component: () => import("@/views/SecretKeys"),
  },
  //角色管理
  {
    path: "/roles",
    title: "角色管理",
    component: () => import("@/views/Roles"),
    children: [
      {
        path: "/roles",
        redirect: "/roles/list",
      },
      {
        path: "/roles/list",
        title: "角色列表",
        component: () => import("@/views/Roles/List"),
      },
      {
        path: "/roles/bindMenus",
        title: "菜单绑定",
        component: () => import("@/views/Roles/BindMenus"),
      },
    ],
  },
  //菜单管理
  {
    path: "/menus",
    title: "菜单管理",
    component: () => import("@/views/Menus"),
  },
  {
    path: "/menus/edit",
    title: "编辑",
    component: () => import("@/views/Menus/Edit"),
  },
  {
    path: "/menus/add",
    title: "新增",
    component: () => import("@/views/Menus/Edit"),
  },

  //帮助文档管理
  {
    path: "/helpDocuments",
    title: "帮助文档管理",
    component: () => import("@/views/HelpDocuments"),
  },
  {
    path: "/helpDocuments/edit",
    title: "编辑",
    component: () => import("@/views/HelpDocuments/Edit"),
  },
  {
    path: "/helpDocuments/add",
    title: "新增",
    component: () => import("@/views/HelpDocuments/Edit"),
  },
  //模板管理
  {
    path: "/templates",
    title: "模板管理",
    component: () => import("@/views/Templates"),
  },
];

export default routes;
