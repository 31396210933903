import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import store from "@/store";
import { getCookie } from '@/utils/utils';

Vue.use(Router);
const excludeRoutes = ["/login"];
const pageRouter = new Router({
  routes,
});
pageRouter.beforeEach((to, from, next) => {
  // let data = sessionStorage.getItem("loginAccountInfo");
  let data = getCookie("loginAccountInfo");
  try {
    data = JSON.parse(data);
  } catch (error) {
    // 错误
  }
  var isLogin = (data && data.token) || store.state?.loginAccountInfo?.token;
  if (
    !isLogin &&
    !excludeRoutes.includes(to.path)
  ) {
    next("/login");
    return;
  }
  next();
});
export default pageRouter;
