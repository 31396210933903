import store from "./index";
import generateID from "@/utils/generateID";
import eventBus from "@/utils/eventBus";
import decomposeComponent from "@/utils/decomposeComponent";
import { $ } from "@/utils/utils";
import { commonStyle, commonAttr } from "@/custom-component/component-list";
import createGroupStyle from "@/utils/createGroupStyle";
import toast from "@/utils/toast";
import { COMPONENT_ATTRS_CONFIG } from '@/utils/attrNameData';

export default {
  state: {
    areaData: {
      // 选中区域包含的组件以及区域位移信息
      style: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
      components: [],
    },
    editor: null,
    attrConfig: Object.assign({}, COMPONENT_ATTRS_CONFIG)
  },
  mutations: {
    setWeatherAttrConfig(state, data) {
      state.attrConfig.Weather.componentAttrs[1].options = data || [];
    },

    getEditor(state) {
      state.editor = $("#editor");
    },

    setAreaData(state, data) {
      state.areaData = data;
    },

    compose({ componentData, areaData, editor }) {
      const components = [];
      const isHasGroup = areaData.components.find(
        (i) => i.component == "Group"
      );
      if (isHasGroup) {
        toast("组合组件不支持再次组合");
        return;
      }
      areaData.components.forEach((component) => {
        if (component.component != "Group") {
          components.push(component);
        } else {
          // 如果要组合的组件中，已经存在组合数据，则需要提前拆分
          const parentStyle = { ...component.style };
          const subComponents = component.propValue;
          const editorRect = editor.getBoundingClientRect();
          const currentScale = editor.getAttribute('data-scale');

          store.commit("deleteComponent");
          subComponents.forEach((component) => {
            decomposeComponent(component, editorRect, parentStyle, currentScale);
            store.commit("addComponent", { component });
          });

          components.push(...component.propValue);
          store.commit("batchDeleteComponent", component.propValue);
        }
      });

      const groupComponent = {
        id: new Date().valueOf(),
        label: "组合",
        component: "Group",
        ...commonAttr,
        style: {
          ...commonStyle,
          ...areaData.style,
        },
        propValue: components,
      };
      createGroupStyle(groupComponent);
      store.commit("addComponent", {
        component: groupComponent,
      });

      eventBus.$emit("hideArea");

      store.commit("batchDeleteComponent", areaData.components);
      store.commit("setCurComponent", {
        component: componentData[componentData.length - 1],
        index: componentData.length - 1,
      });

      areaData.components = [];
    },

    // 将已经放到 Group 组件数据删除，也就是在 componentData 中删除，因为它们已经放到 Group 组件中了
    batchDeleteComponent({ componentData, selectedCurrentPage }, deleteData) {
      // 删除当前组件的错误提示
      const data =
        (sessionStorage.getItem("canvasErrorMap") &&
          JSON.parse(sessionStorage.getItem("canvasErrorMap"))) ||
        {};
      deleteData.forEach((component) => {
        for (let i = 0, len = componentData.length; i < len; i++) {
          if (component.id == componentData[i].id) {
            delete data[selectedCurrentPage][component.id];
            componentData.splice(i, 1);
            break;
          }
        }
      });
      sessionStorage.setItem("canvasErrorMap", JSON.stringify(data));
      this.commit("setCurErrorMap");
    },

    decompose({ curComponent, editor }) {
      const parentStyle = { ...curComponent.style };
      const components = curComponent.propValue;
      const editorRect = editor.getBoundingClientRect();
      const currentScale = editor.getAttribute('data-scale');

      store.commit("deleteComponent");
      components.forEach((component) => {
        decomposeComponent(component, editorRect, parentStyle, currentScale);
        store.commit("addComponent", { component });
      });
    },
  },
};
