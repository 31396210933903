import Vue from "vue";
import API from "@/api";
import { Message } from "element-ui";
export default {
  state: {
    directiveTypesList: [],
  },
  actions: {
    getDirectiveTypesAction(
      { state, commit },
      { page_num = 1, page_size = 999 }
    ) {
      API.queryDirectiveTypesList({ page_num, page_size }).then((res) => {
        if (res.status != "success") {
          Message.error(res.msg);
          return;
        }
        commit("setDirectiveTypes", res?.data?.result || []);
      });
    },
  },
  mutations: {
    setDirectiveTypes(state, data) {
      state.directiveTypesList = data;
    },
  },
};
