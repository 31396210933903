import ajax from "./request.js";
import { HOST } from './base';

function queryLeftTreeList(data) {
  return ajax(`${HOST}/instructionCompanyPublic/getTree`, {
    method: "POST",
    data
  });
}

function addOrUpdateTreeData(data) {
  return ajax(`${HOST}/instructionCompanyPublic/addOrUpdate`, {
    method: "POST",
    data
  });
}


function deleteTreeData(data) {
  return ajax(`${HOST}/instructionCompanyPublic/deleteByIds`, {
    method: "POST",
    data
  });
}

function queryRightTableList(data) {
  return ajax(`${HOST}/instructionPublic/list`, {
    method: "POST",
    data
  });
}

function findTableDataById(data) {
  return ajax(`${HOST}/instructionPublic/findById`, {
    method: "POST",
    data
  });
}

function addOrUpdateTableData(data) {
  return ajax(`${HOST}/instructionPublic/addOrUpdate`, {
    method: "POST",
    data
  });
}

function deleteTableData(data) {
  return ajax(`${HOST}/instructionPublic/deleteByIds`, {
    method: "POST",
    data
  });
}

function updateStatus(data) {
  return ajax(`${HOST}/instructionPublic/updateStatus`, {
    method: "POST",
    data
  });
}

export default {
  queryLeftTreeList,
  addOrUpdateTreeData,
  deleteTreeData,
  queryRightTableList,
  findTableDataById,
  addOrUpdateTableData,
  deleteTableData,
  updateStatus
};
