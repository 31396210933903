import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/template`
  : "/api/cloud/template";

function shareProject(data) {
  return ajax(`${href}/add`, {
    method: "POST",
    data,
  });
}
function getTemplatesList(data) {
  return ajax(`${href}/list`, {
    method: "POST",
    data,
  });
}

function updateTemplateStatus(data) {
  return ajax(`${href}/updateStatus`, {
    method: "POST",
    data,
  });
}

export default {
  shareProject,
  getTemplatesList,
  updateTemplateStatus,
};
