import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/projectPublic`
  : "/api/cloud/projectPublic";
const href1 = bool
  ? `https://${window.location.host}/api/cloud/welcome/findList`
  : "/api/cloud/welcome/findList";

function queryCommonTemplates(data) {
  return ajax(`${href}/list`, {
    method: "POST",
    data,
  });
}

function deleteCommonTemplates(data) {
  return ajax(`${href}/deleteByIds`, {
    method: "POST",
    data,
  });
}

function getDownloadList(data) {
  return ajax(`${href1}`, {
    method: "POST",
    data,
  });
}

export default {
  queryCommonTemplates,
  deleteCommonTemplates,
  getDownloadList,
};
