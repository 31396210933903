import Vue from "vue";

export default {
  mutations: {
    bindDirectives({ curComponent }, data) {
      curComponent.networkConfig.directives = [...(data || [])];
    },
    bindDirectives2({ curComponent }, data) {
      curComponent.networkConfig.directives2 = [...(data || [])];
    },
  },
};
