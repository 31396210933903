import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/menu`
  : "/api/cloud/menu";

function getChildMenusList(data) {
  return ajax(`${href}/queryMenuList`, {
    method: "POST",
    data,
  });
}

function getAllMenusTreeData(data) {
  return ajax(`${href}/queryMenuAllTree`, {
    method: "GET",
    params: data,
  });
}

function deleteMenu(data) {
  return ajax(`${href}/delete`, {
    method: "POST",
    data,
  });
}

function getMenuInfo(data) {
  return ajax(`${href}/queryMenuById`, {
    method: "GET",
    params: data,
  });
}

function menuOperation(data) {
  return ajax(`${href}/${data.id ? "update" : "save"}`, {
    method: "POST",
    data,
  });
}

function getRoleBindedMenus(data) {
  return ajax(`${href}/queryMenuTreeByRoleId`, {
    method: "POST",
    data,
  });
}
function getUserAuthorizedMenus(data) {
  return ajax(`${href}/queryMenuTreeByUserId`, {
    method: "POST",
    data,
  });
}

export default {
  getChildMenusList,
  getAllMenusTreeData,
  deleteMenu,
  getMenuInfo,
  menuOperation,
  getRoleBindedMenus,
  getUserAuthorizedMenus,
};
