import API from "@/api";

export default {
    state: {
        directiveTreeDatas: [],
        directiveTableList: [],
        directiveTableListTotal: 0,
        materialTableList: [],
        materialTableListTotal: 0,
        materialTypeList: []
    },
    getters: {
        directiveTreeDatas(state) {
            return state.directiveTreeDatas;
        },
        directiveTableDatas(state) {
            return state.directiveTableList;
        },
        directiveTableListTotal(state) {
            return state.directiveTableListTotal;
        },
        materialTableDatas(state) {
            return state.materialTableList;
        },
        materialTableListTotal(state) {
            return state.materialTableListTotal;
        },
        materialTypeList(state) {
            return state.materialTypeList;
        }
    },
    mutations: {
        SET_DIRECTIVE_TREE_DATAS(state, datas) {
            state.directiveTreeDatas = datas ? [datas] : [];
        },
        SET_DIRECTIVE_TABLE_DATAS(state, datas) {
            state.directiveTableList = datas || [];
        },
        SET_DIRECTIVE_TABLE_TOTAL(state, total) {
            state.directiveTableListTotal = total || 0;
        },
        SET_MATERIAL_TABLE_DATAS(state, datas) {
            state.materialTableList = datas || [];
        },
        SET_MATERIAL_TABLE_TOTAL(state, total) {
            state.materialTableListTotal = total || 0;
        },
        SET_MATERIAL_TYPE_DATAS(state, datas) {
            state.materialTypeList = datas || [];
        },
    },
    actions: {
        async getDirectiveTree({commit}, params) {
            const ret = await API.publicDirective.queryLeftTreeList(params);
            commit('SET_DIRECTIVE_TREE_DATAS', ret && ret.data);
        },
        async getDirectiveTableList({commit}, params) {
            const ret = await API.publicDirective.queryRightTableList(params);
            commit('SET_DIRECTIVE_TABLE_DATAS', ret && ret.data && ret.data.result);
            commit('SET_DIRECTIVE_TABLE_TOTAL', ret && ret.data && ret.data.total);
        },
        async addOrUpdateTreeData({}, params) {
            return await API.publicDirective.addOrUpdateTreeData(params);
        },
        async deleteTreeData({}, params) {
            return await API.publicDirective.deleteTreeData(params);
        },
        async findTableDataById({}, params) {
            return await API.publicDirective.findTableDataById(params);
        },
        async addOrUpdateTableData({}, params) {
            return await API.publicDirective.addOrUpdateTableData(params);
        },
        async deleteTableData({}, params) {
            return await API.publicDirective.deleteTableData(params);
        },
        async updateStatus({}, params) {
            return await API.publicDirective.updateStatus(params);
        },
        async getMaterialTableList({commit}, params) {
            const ret = await API.publicMaterial.queryTableList(params);
            commit('SET_MATERIAL_TABLE_DATAS', ret && ret.data && ret.data.result);
            commit('SET_MATERIAL_TABLE_TOTAL', ret && ret.data && ret.data.total);
        },
        async getMaterialTypeList({commit}) {
            const ret = await API.publicMaterial.findPicTypeByCode({
                type: 'file_type_public'
            });
            const data = (ret && ret.data || []).reduce((arr, item) => {
                arr.push({
                    label: item.lable,
                    value: item.value
                });
                return arr;
            }, []);
            commit('SET_MATERIAL_TYPE_DATAS', data);
        },
        async deleteMaterialData({}, params) {
            return await API.publicMaterial.deletePicData(params);
        },
    }
};