import ajax from "./request.js";
import { HOST } from './base';

function queryList(data) {
  return ajax(`${HOST}/apkVersion/list`, {
    method: "POST",
    data
  });
}

function saveData(data) {
  return ajax(`${HOST}/apkVersion/save`, {
    method: "POST",
    data
  });
}


function updateData(data) {
  return ajax(`${HOST}/apkVersion/update`, {
    method: "POST",
    data
  });
}

function deleteDatas(data) {
  return ajax(`${HOST}/apkVersion/deleteByIds`, {
    method: "POST",
    data
  });
}

function updateStatus(data) {
  return ajax(`${HOST}/apkVersion/updateStatus`, {
    method: "POST",
    data
  });
}

function findDataById(data) {
  return ajax(`${HOST}/apkVersion/findById`, {
    method: "POST",
    data
  });
}

export default {
  queryList,
  saveData,
  updateData,
  deleteDatas,
  findDataById,
  updateStatus
};
