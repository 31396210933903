<template>
  <div class="appHeader">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        :to="{ path: p.path }"
        v-for="p in initBreadCrumbs()"
        :key="p.path"
      >
        {{ p.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <el-dropdown
        class="userNameDrop"
        placement="bottom"
        @command="handleCommand"
      >
        <span class="userName">
          <i class="iconfont icon-user" />
          {{ loginAccountInfo.cn_name }}
        </span>
        <el-dropdown-menu slot="dropdown" class="userDropMenus">
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import routes from "@/router/routes";
import { flattenRoute } from "@/utils/menu";
import { mapState } from "vuex";
import { removeCookie } from '@/utils/utils';
export default {
  data() {
    return {};
  },
  mounted() {
    this.initActiveMenu();
  },
  computed: mapState(["loginAccountInfo"]),
  methods: {
    initBreadCrumbs() {
      //生成面包屑
      const arr = _.compact(this.$route.path.split("/"));
      const joinPaths = arr.reduce((re, item, index) => {
        const p = `${index == 0 ? "" : re[index - 1].path}/${item}`;
        const pathObj = flattenRoute(routes).find((i) => i.path == p);
        re.push(pathObj);
        return re;
      }, []);
      return joinPaths;
    },
    initActiveMenu() {
      this.activeMenu = this.$route.path;
    },
    handleCommand(c) {
      if (c == "logout") {
        // sessionStorage.removeItem("loginAccountInfo");
        removeCookie("loginAccountInfo");
        sessionStorage.removeItem("routesData");
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.appHeader {
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 16px;
  background-color: #fff;
  box-shadow: 0 0.04rem 0.06rem 0 rgb(235 238 245 / 71%), 0 0.01rem 0 0 #ebeef5;
  margin-bottom: 0.06rem;
  .userNameDrop {
    margin-right: 12px;
    .userName {
      cursor: pointer;
      margin-right: 40px;
      font-size: 16px;
      display: flex;
      align-items: center;
      &:hover {
        color: #1890ff !important;
      }
      .iconfont {
        font-size: 18px;
        margin-right: 6px;
      }
    }
  }
}
.userDropMenus {
  .el-dropdown-menu__item {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    width: 100px;
    text-align: center;
  }
}
</style>