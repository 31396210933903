import ajax from "./request.js";

let bool = process.env.NODE_ENV === "production";
const href = bool
  ? `https://${window.location.host}/api/cloud/user`
  : "/api/cloud/user";

function queryRegisteredUsers(data) {
  return ajax(`${href}/queryUserList`, {
    method: "POST",
    data,
  });
}
function updateUserStatus(data) {
  return ajax(`${href}/update`, {
    method: "POST",
    data,
  });
}

function queryUserById(data) {
  return ajax(`${href}/queryUserById`, {
    method: "GET",
    params: data,
  });
}

function queryAllUsers(data) {
  return ajax(`${href}/queryAllUser`, {
    method: "POST",
    data,
  });
}

function bindPhoneNumber(data) {
  return ajax(`${href}/bind_phone`, {
    method: "POST",
    data,
  });
}

export default {
  queryRegisteredUsers,
  updateUserStatus,
  queryUserById,
  queryAllUsers,
  bindPhoneNumber,
};
